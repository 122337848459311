<template lang="pug">
.card.flex.flex--aic.flex--jcsb(:class="{disabled}")
	component(v-if="icon" :is="`icon-${icon}`")
	p
		b(v-if="title !== null || typeof title !== 'undefined'") {{ title }}
		span(v-if="subtitle !== null || typeof subtitle !== 'undefined'") {{ subtitle }}
	button(v-if="button" :style="{fontSize}" @click="$emit('onClick')") {{ button }}
</template>

<script>
import IconDoc from './icons/IconDoc'
import IconUser from './icons/IconUser'
import IconRegion from './icons/IconRegion'
import IconAbstract from './icons/IconAbstract'

export default {
	name: 'BaseCard',
	components: {
		IconDoc,
		IconUser,
		IconRegion,
		IconAbstract
	},
	props: {
		title: {
			type: [String, Number, null],
			default: null
		},
		subtitle: {
			type: [String, Number, null],
			default: null
		},
		button: {
			type: [String, null],
			default: null
		},
		buttonSize: {
			type: [Number, null],
			default: null
		},
		icon: {
			type: [String, null],
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	emits: ['onClick'],
	setup (props) {
		const fontSize = `${props.buttonSize}px` || null

		return {
			fontSize
		}
	}
}
</script>

<style lang="scss" scoped>
.card {
	position: relative;
	padding: 8px 8px 8px 40px;
	background-color: rgba($bgMain, 0.1);
	border-radius: $radiusPrimaryHalf;
	height: 100%;
	font-size: 14px;

	svg {
		position: absolute;
		width: 24px;
		height: 24px;
		top: 50%;
		left: 8px;
		transform: translateY(-50%);
	}

	p {
		b {
			display: block;
		}
	}

	button {
		border: none;
		background: none;
		font-weight: bold;
		color: #458daf;
		cursor: pointer;
	}
}
</style>
