<template lang="pug">
.collapse(:class="{active}" @click="toggle")
	.collapse__head {{ head }}
	.collapse__content(:style="{height}" @click.stop)
		.collapse__wrapper(ref="wrapper")
			slot
</template>

<script>
import { ref } from 'vue'

export default {
	name: 'BaseCollapse',
	props: {
		head: {
			type: String,
			required: true
		}
	},
	setup () {
		const wrapper = ref(null)
		const active = ref(false)
		const height = ref(0)

		const toggle = () => {
			active.value = !active.value
			setHeight()
		}

		const setHeight = () => {
			height.value = active.value
				? wrapper.value.getBoundingClientRect().height + 'px'
				: 0
		}

		return {
			wrapper,
			active,
			height,
			toggle,
			setHeight
		}
	}
}
</script>

<style scoped lang="scss">
.collapse {
	cursor: pointer;
	overflow: hidden;
	position: relative;

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 4px;
		left: 8px;
		width: 2px;
		height: 10px;
		background-color: $bgMain;
		transition: 0.3s ease background-color;
	}

	&::before {
		-webkit-transform: rotate(90deg);
		transform: rotate(90deg);
	}

	&::after {
		transition: 0.3s ease transform;
	}

	&__head {
		color: $mainColor;
		font-weight: bold;
		padding-left: 20px;
	}

	&__content {
		cursor: default;
		position: relative;
		transition: 0.3s ease height;
	}

	&__wrapper {
		box-sizing: border-box;
		position: absolute;
		top: 0;
		left: 0;
		font-size: 14px;
		line-height: 18px;
		padding-top: 16px;
		max-width: 707px;
		opacity: 0;
		transition: 0.3s ease opacity;
	}

	&.active {
		&::after {
			-webkit-transform: rotate(-90deg);
			transform: rotate(-90deg);
		}

		.collapse {
			&__wrapper {
				opacity: 1;
				-webkit-transform: scaleY(1);
				transform: scaleY(1);
			}
		}
	}

	@include for-tablet {
		&:hover {
			&::before,
			&::after {
				background-color: $mainColorHover;
			}
		}
	}
}
</style>
