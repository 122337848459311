<template lang="pug">
BaseCollapse(:head="head")
	p(v-for="field in fields" )
		b {{ field.title }}:{{ ' ' }}
		span {{ field.value }}
</template>

<script>
import BaseCollapse from '../ui/BaseCollapse'

export default {
	name: 'CollapseFish',
	components: { BaseCollapse },
	props: {
		head: {
			type: String,
			required: true
		},
		fields: {
			type: Array,
			required: true
		}
	}
}
</script>
